import React from "react";
import { useSelector } from "react-redux";
import classes from "./backendDataInfoPage.module.css";
import { selectEmptyFields } from "./backendInfoSlice";
import { selectVehicleUuID } from "./vehicleUuIDSlice";
import { selectVehicleUsers } from "./vehicleUsersSlice";
import {
  selectIsLoadingDataPrimaryUserClaims,
  selectNoPrimaryUserFound,
} from "./primaryUserClaimsSlice";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import { Typography } from "@mui/material";

function WideTable() {
  const vehicleUuID = useSelector(selectVehicleUuID);
  const vehicleUsers = useSelector(selectVehicleUsers);
  const emptyFields = useSelector(selectEmptyFields);
  const noPrimaryUserFound = useSelector(selectNoPrimaryUserFound);
  const isLoadingDataForPrimaryUser = useSelector(
    selectIsLoadingDataPrimaryUserClaims
  );

  if (isLoadingDataForPrimaryUser) {
    return <LoadingSpinner />;
  } else if (
    (!vehicleUsers.Data || vehicleUsers.Data.length === 0) &&
    !emptyFields &&
    noPrimaryUserFound
  ) {
    return (
      <Typography
        style={{
          fontFamily: "var(--regular)",
          color: "var(--primary)",
          fontSize: "1.25rem",
        }}
      >
        "Not available due to missing primary user for vehicle on VW.AC"
      </Typography>
    );
  } else if (
    (!vehicleUsers.Data || vehicleUsers.Data.length === 0) &&
    emptyFields
  ) {
    return "No data available";
  }
  // Check if vehicleUsers.Data is an array before attempting to sort
  if (!Array.isArray(vehicleUsers.Data)) {
    // Handle the case where vehicleUsers.Data is not an array so the page does not crash
    return "Data is not available or not in the correct format";
  }
  const sortedUsers = [...vehicleUsers.Data].sort((a, b) => {
    if (a.Role === "PRIMARY_USER" && b.Role !== "PRIMARY_USER") {
      return -1;
    } else if (a.Role !== "PRIMARY_USER" && b.Role === "PRIMARY_USER") {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <div className={classes.flexContainerRowWideTableTitle}>
        VW.AC Vehicle Users
      </div>
      <div className={classes.flexContainerRowWideTable}>
        <div className={classes.wideTableDiv}>
          {/* Header section */}
          <div className={classes.wideTableHeader}>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1.5, userSelect: "text", cursor: "auto" }}
            >
              Role
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              MBB ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1.75, userSelect: "text", cursor: "auto" }}
            >
              SSO ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              VWAC ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Sec. Lv.
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 2.5, userSelect: "text", cursor: "auto" }}
            >
              Status
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              Ownership Verification
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1, userSelect: "text", cursor: "auto" }}
            >
              Country
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1, userSelect: "text", cursor: "auto" }}
            >
              IDP
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 1
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 2
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 3
            </div>
          </div>
          {/* BODY SECTION */}
          <div className={classes.wideTableBody}>
            {sortedUsers.map((item, i) => (
              <div className={classes.wideTableRow} key={i}>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1.5, userSelect: "text", cursor: "auto" }}
                >
                  {item.Role}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {item.MbbUserId}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1.75, userSelect: "text", cursor: "auto" }}
                >
                  {item.IdpUserId}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {item.VwacUserId}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {item.SecurityLevel}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 2.5, userSelect: "text", cursor: "auto" }}
                >
                  {item.Status}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {item.VerificationStatus}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1, userSelect: "text", cursor: "auto" }}
                >
                  {item.HomeCountry}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1, userSelect: "text", cursor: "auto" }}
                >
                  {item.Idp}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {vehicleUuID.VehicleOrgLevel1}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {vehicleUuID.VehicleOrgLevel2}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {vehicleUuID.VehicleOrgLevel3}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default WideTable;
